<template>
  <v-hover #default="{ hover }">
    <div>
      <v-list-item
        :ripple="false"
        three-line
        :to="`/admin/polls/${poll._id}`"
        color="blue"
      >
        <v-list-item-avatar :color="avatarColor">
          <v-icon dark> {{ avatarIcon }} </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ poll.question }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <span class="mr-4">
              <v-icon size="16" class="mr-1"> mdi-account </v-icon>
              {{ (poll.votes && Object.keys(poll.votes).length) || 0 }}
            </span>
            <span v-if="poll.isLocked" class="mr-4">
              <v-icon size="16"> mdi-lock </v-icon>
            </span>
            <span v-if="poll.isShowingResults" class="mr-4">
              <v-icon size="16" color="blue"> mdi-signal </v-icon>
            </span>
            <span v-if="isPresenting" class="mr-4">
              <v-icon size="16" color="amber"> mdi-presentation-play </v-icon>
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <div v-if="poll.isActive || hover">
            <v-tooltip v-if="!isPresenting" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color=""
                  v-bind="attrs"
                  @click="setPresenting(true)"
                  v-on="on"
                >
                  <v-icon small>mdi-presentation</v-icon>
                </v-btn>
              </template>
              <span>Show in Presenter View</span>
            </v-tooltip>
            <!--
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="amber"
                  v-bind="attrs"
                  @click="setPresenting(false)"
                  v-on="on"
                >
                  <v-icon small>mdi-presentation-play</v-icon>
                </v-btn>
              </template>
              <span>Hide in Presenter View</span>
            </v-tooltip>
            -->
            <v-tooltip v-if="!poll.isLocked" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color=""
                  v-bind="attrs"
                  @click="setLocked(true)"
                  v-on="on"
                >
                  <v-icon small>mdi-lock-open-variant-outline</v-icon>
                </v-btn>
              </template>
              <span>Lock voting</span>
            </v-tooltip>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color=""
                  v-bind="attrs"
                  @click="setLocked(false)"
                  v-on="on"
                >
                  <v-icon small>mdi-lock</v-icon>
                </v-btn>
              </template>
              <span>Unlock voting</span>
            </v-tooltip>
            <v-tooltip v-if="!poll.isShowingResults" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color=""
                  v-bind="attrs"
                  @click="setShowingResults(true)"
                  v-on="on"
                >
                  <v-icon small>mdi-signal-off</v-icon>
                </v-btn>
              </template>
              <span>Show results</span>
            </v-tooltip>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  color="blue"
                  @click="setShowingResults(false)"
                  v-on="on"
                >
                  <v-icon small>mdi-signal</v-icon>
                </v-btn>
              </template>
              <span>Hide results</span>
            </v-tooltip>
            <v-tooltip v-if="!poll.isActive" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  large
                  color="green"
                  v-bind="attrs"
                  @click="setActive(true)"
                  v-on="on"
                >
                  <v-icon x-large>mdi-play-circle</v-icon>
                </v-btn>
              </template>
              <span>Activate</span>
            </v-tooltip>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  large
                  color="red"
                  v-bind="attrs"
                  @click="setActive(false)"
                  v-on="on"
                >
                  <v-icon x-large>mdi-stop-circle</v-icon>
                </v-btn>
              </template>
              <span>Deactivate</span>
            </v-tooltip>
          </div>
        </v-list-item-action>
      </v-list-item>
      <v-divider />
    </div>
  </v-hover>
</template>

<script>
export default {
  name: 'AdminPollItem',
  props: {
    poll: {
      type: Object,
      default: () => {}
    },
    isPresenting: {
      type: Boolean
    }
  },
  data() {
    return {
      busy: false
    };
  },
  computed: {
    avatarColor() {
      if (this.poll.isActive) {
        if (this.poll.isLocked) {
          return 'blue lighten-2';
        }
        return 'green lighten-2';
      }

      return 'grey lighten-2';
    },
    avatarIcon() {
      if (this.poll.isActive) {
        if (this.poll.isLocked) {
          if (this.poll.isShowingResults) {
            return 'mdi-signal';
          }
          return 'mdi-lock';
        }
        return 'mdi-play';
      }

      return 'mdi-eye-off';
    }
  },
  methods: {
    setActive(isActive) {
      this.$socket.emit('call', 'polls.update', {
        id: this.poll._id,
        isActive: !!isActive
      });
    },
    setShowingResults(isShowingResults) {
      this.$socket.emit('call', 'polls.update', {
        id: this.poll._id,
        isShowingResults: !!isShowingResults
      });
    },
    setLocked(isLocked) {
      this.$socket.emit('call', 'polls.update', {
        id: this.poll._id,
        isLocked: !!isLocked
      });
    },
    setPresenting() {
      this.$socket.emit('call', 'presenter.set', {
        stage: this.poll.stage,
        type: 'polls',
        id: this.poll._id
      });
    }
  }
};
</script>

<style></style>
