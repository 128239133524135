<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-tabs centered>
          <v-tab to="/stage/main/1">Day One</v-tab>
          <v-tab to="/stage/main/2">Day Two</v-tab>
          <v-tab to="/stage/main/3">Day Three</v-tab>
        </v-tabs>
        <router-view />
        <v-row class="mt-5">
          <v-col>
            <v-img :src="require('@/assets/sponsors.png')" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {
    if (!this.$route.params.day) {
      this.$router.push('/stage/main/1');
    }
  },
  beforeDestroy() {},
  head() {
    return {
      title: 'Main Stage'
    };
  }
};
</script>
