<template>
  <div />
</template>

<route>
{
  "meta": {
    "auth": false
  }
}
</route>

<script>
import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';

const randomString = customAlphabet(alphanumeric, 7);

export default {
  name: 'OnsiteLanding',
  layout: 'guest',
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    async init() {
      if (
        this.$store.state.auth.isInitialized &&
        this.$store.state.auth.token
      ) {
        this.$router.push('/onsite/main');
      } else {
        console.log('not auth');
        await this.$store.dispatch('auth/login', {
          email: `visitor.${randomString()}@visitor.wlnc.tv`
        });
        this.$router.push('/onsite/main');
      }
    }
  },
  created() {
    this.init();
  },
  beforeDestroy() {}
};
</script>

<style></style>
