<template>
  <div>
    <AskQuestion />
    <ListQuestions v-if="$store.state.settings.questions.visible" />
  </div>
</template>

<script>
import ListQuestions from './ListQuestions';
import AskQuestion from './AskQuestion';

export default {
  name: 'Qna',
  components: {
    ListQuestions,
    AskQuestion
  },
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>
