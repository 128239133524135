<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" lg="4" xl="3">
        <Sidebar />
        <v-row class="mt-5">
          <v-col>
            <v-img :src="require('@/assets/sponsors.png')" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<route>
{}
</route>

<script>
import Sidebar from '@/components/sidebar/Sidebar';

export default {
  name: 'Stage',
  components: { Sidebar },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  beforeDestroy() {}
};
</script>

<style></style>
