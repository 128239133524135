<template>
  <div class="text-center mt-10">
    <router-link to="/">
      <img src="../assets/Wlnc_Logo_22.png" class="logo" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  created() {}
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.logo {
  width: 450px;
  height: auto;
  max-width: 100%;
  margin-top: -12px;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .logo {
    width: 70%;
  }
}
</style>
