<template>
  <div v-if="poll">
    <v-card-title style="word-break: keep-all; margin-right: 50px">
      {{ poll.question }}
    </v-card-title>
    <v-menu bottom left rounded>
      <template v-slot:activator="{ on, attrs }">
        <v-btn absolute top right icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item @click="pollEditing = true">
          <v-list-item-icon>
            <v-icon> mdi-pencil </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Edit</v-list-item-title>
        </v-list-item>
        <v-list-item @click="resetVotes()">
          <v-list-item-icon>
            <v-icon color="red"> mdi-refresh </v-icon>
          </v-list-item-icon>
          <v-list-item-title class="red--text">Reset Votes</v-list-item-title>
        </v-list-item>
        <!--
        <v-list-item @click="deletePoll()">
          <v-list-item-icon>
            <v-icon color="red"> mdi-delete </v-icon>
          </v-list-item-icon>
          <v-list-item-title class="red--text"> Delete </v-list-item-title>
        </v-list-item>
        -->
      </v-list>
    </v-menu>
    <v-divider />
    <v-banner v-if="!poll.isShowingResults" icon="mdi-signal-off">
      Results are hidden
      <template #actions>
        <v-btn color="blue" outlined @click="setShowingResults(true)">
          <v-icon left>mdi-signal</v-icon> Show Results
        </v-btn>
      </template>
    </v-banner>
    <v-banner v-if="poll.isLocked" icon="mdi-lock">
      Voting locked
      <template #actions>
        <v-btn outlined color="green" @click="setLocked(false)">
          <v-icon left>mdi-lock-open-variant-outline</v-icon> Unlock Voting
        </v-btn>
      </template>
    </v-banner>
    <v-card-text>
      <div v-for="opt in poll.options" :key="opt.id">
        <p class="mb-0 mt-5">{{ opt.text }}</p>
        <v-progress-linear
          :value="getValue(opt.id)"
          rounded
          striped
          color="blue lighten-3"
          height="25"
        >
          <template v-slot="{ value }">
            <strong>{{ value.toFixed(1) }}%</strong>
          </template>
        </v-progress-linear>
      </div>
    </v-card-text>
    <v-dialog v-model="pollEditing" persistent max-width="400">
      <AdminPollCreate
        :key="'edit_' + poll._id"
        :edit="poll"
        @close="pollEditing = false"
      />
    </v-dialog>
  </div>
</template>

<route>
{
  "meta": {
    "admin": true
  }
}
</route>

<script>
import AdminPollCreate from '@/components/admin/PollCreate';
import { groupBy, mapValues } from 'lodash';
export default {
  name: 'PollDetails',
  components: { AdminPollCreate },
  created() {
    this.$socket.emit(
      'call',
      'polls.get',
      { id: this.$route.params.pollId },
      (err, data) => {
        this.poll = data;
      }
    );
    // this.$store.dispatch('polls/bindCurrentPollRef', this.$route.params.pollId)
    // store.dispatch('profiles/bindCurrentMessagesRef', params.userId)
  },
  data() {
    return {
      poll: {},
      pollEditing: false
    };
  },
  sockets: {
    poll_update(data) {
      if (data._id === this.poll._id) {
        this.poll = data;
      }
    }
  },
  computed: {
    resetVotesCallable() {
      return () => {};
      // return db.httpsCallable('resetVotes')
    },
    results() {
      if (this.poll.votes) {
        return mapValues(groupBy(this.poll.votes), i => i.length);
      } else return {};
    },
    ratios() {
      if (this.poll.votes) {
        return mapValues(
          this.results,
          i => i / Object.keys(this.poll.votes).length
        );
      } else return {};
    }
  },
  watch: {
    $route(to) {
      if (to.params?.pollId) {
        this.$socket.emit(
          'call',
          'polls.get',
          { id: to.params.pollId },
          (err, data) => {
            this.poll = data;
          }
        );
      }
    }
  },
  methods: {
    getValue(opt) {
      return (this.ratios?.[opt] || 0) * 100;
    },
    setActive(isActive) {
      this.$socket.emit('call', 'polls.update', {
        id: this.poll._id,
        isActive: !!isActive
      });
    },
    setShowingResults(isShowingResults) {
      this.$socket.emit('call', 'polls.update', {
        id: this.poll._id,
        isShowingResults: !!isShowingResults
      });
    },
    setLocked(isLocked) {
      this.$socket.emit('call', 'polls.update', {
        id: this.poll._id,
        isLocked: !!isLocked
      });
    },
    resetVotes() {
      this.$socket.emit('call', 'polls.update', {
        id: this.poll._id,
        votes: {}
      });
    },
    async deletePoll() {
      await this.$socket.emit('call', 'polls.delete', { id: this.poll._id });
      this.$router.push('/admin/polls');
    }
  }
};
</script>
<style></style>
