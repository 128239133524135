<template>
  <v-hover #default="{ hover }">
    <div>
      <v-list-item dense two-line>
        <v-list-item-avatar color="grey lighten-2">
          {{ /* initials */ }}
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ question.displayName }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-icon size="small" class="mr-1"> mdi-thumb-up-outline </v-icon>
            {{ likeCount }}
            <v-icon size="small" class="ml-4 mr-1"> mdi-clock-outline </v-icon>
            {{ $moment(question.createdAt).format('HH:mm') }}
            <v-chip
              v-if="question.answered"
              class="ml-4"
              x-small
              color="green"
              outlined
            >
              <v-icon x-small left> mdi-check </v-icon>
              Answered
            </v-chip>
            <v-chip
              v-if="isPresenting"
              class="ml-4"
              x-small
              color="amber"
              outlined
            >
              <v-icon x-small left> mdi-presentation-play </v-icon>
              Presenting
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-show="hover && !edit">
          <div v-if="pending">
            <v-tooltip
              v-if="question.rejected !== true && !isPresenting"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  large
                  color="amber"
                  v-bind="attrs"
                  @click="present"
                  v-on="on"
                >
                  <v-icon>mdi-presentation</v-icon>
                </v-btn>
              </template>
              <span>Present</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  large
                  color="green"
                  v-bind="attrs"
                  @click="approve"
                  v-on="on"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </template>
              <span>Approve</span>
            </v-tooltip>
            <v-tooltip v-if="question.rejected !== true" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  large
                  color="red"
                  v-bind="attrs"
                  @click="reject"
                  v-on="on"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Reject</span>
            </v-tooltip>
          </div>
          <div v-else>
            <v-tooltip v-if="!isPresenting" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  large
                  color="amber"
                  v-bind="attrs"
                  @click="present"
                  v-on="on"
                >
                  <v-icon>mdi-presentation</v-icon>
                </v-btn>
              </template>
              <span>Present</span>
            </v-tooltip>
            <v-tooltip v-if="!question.answered" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  large
                  color="green"
                  v-bind="attrs"
                  @click="answer"
                  v-on="on"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </template>
              <span>Mark as Answered</span>
            </v-tooltip>

            <v-tooltip v-if="!question.archived" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon large v-bind="attrs" @click="archive" v-on="on">
                  <v-icon>mdi-archive-arrow-down-outline</v-icon>
                </v-btn>
              </template>
              <span>Archive and Hide</span>
            </v-tooltip>
          </div>
        </v-list-item-action>
      </v-list-item>
      <div v-if="!edit">
        <p class="body-2 text--secondary my-0 px-5">
          {{ question.text }}
        </p>
        <div class="d-flex justify-end px-3 pb-3">
          <v-menu bottom left rounded>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item @click="edit = true">
                <v-list-item-icon>
                  <v-icon> mdi-pencil </v-icon>
                </v-list-item-icon>
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
              <v-list-item @click="reply = true">
                <v-list-item-icon>
                  <v-icon> mdi-reply </v-icon>
                </v-list-item-icon>
                <v-list-item-title>Reply</v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteItem">
                <v-list-item-icon>
                  <v-icon color="red"> mdi-delete </v-icon>
                </v-list-item-icon>
                <v-list-item-title class="red--text">
                  Delete Permanently
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div v-else class="mx-5">
        <v-textarea
          v-model="editQuestion"
          :disabled="busy"
          :loading="busy"
          auto-grow
          rows="2"
          dense
          name="question"
        />
        <div class="d-flex justify-end mb-2">
          <v-btn text @click="resetEdit"> Cancel </v-btn>
          <v-btn
            :disabled="busy"
            :loading="busy"
            text
            color="primary"
            @click="editText"
          >
            Save
          </v-btn>
        </div>
      </div>
      <div v-if="reply === true" class="mx-5">
        <v-textarea
          label="Reply"
          v-model="replyQuestion"
          :disabled="busy"
          :loading="busy"
          auto-grow
          rows="2"
          dense
          name="reply"
        />
        <div class="d-flex justify-end mb-2">
          <v-btn text @click="resetReply"> Cancel </v-btn>
          <v-btn
            :disabled="busy"
            :loading="busy"
            text
            color="primary"
            @click="sendReply"
          >
            Save
          </v-btn>
        </div>
      </div>
      <div class="pa-3" v-else-if="question.reply">
        <v-alert
          color="grey lighten-3"
          dense
          class="text-caption"
          border="left"
          >{{ question.reply }}</v-alert
        >
      </div>
      <v-divider />
    </div>
  </v-hover>
</template>

<script>
import { initials } from '../../lib/utils';
// import { db } from '../../lib/db'

export default {
  name: 'Question',
  props: {
    question: {
      type: Object,
      default: () => {}
    },
    pending: Boolean,
    isPresenting: Boolean
  },
  data() {
    return {
      busy: false,
      edit: false,
      reply: false,
      editQuestion: '',
      replyQuestion: ''
    };
  },
  computed: {
    initials() {
      return initials(this.question?.displayName);
    },
    likeCount() {
      return this.question?.likes?.length || 0;
    }
  },
  watch: {
    question() {
      this.resetEdit();
    }
  },
  created() {
    this.resetEdit();
    this.resetReply();
  },
  methods: {
    resetEdit() {
      this.edit = false;
      this.editQuestion = this.question.text;
    },
    resetReply() {
      this.reply = false;
      this.replyQuestion = this.question.reply || '';
    },
    async editText() {
      this.busy = true;
      await this.$socket.emit('call', 'questions.update', {
        id: this.question._id,
        text: this.editQuestion
      });
      this.busy = false;
      this.edit = false;
    },
    async sendReply() {
      this.busy = true;
      await this.$socket.emit('call', 'questions.update', {
        id: this.question._id,
        reply: this.replyQuestion
      });
      this.busy = false;
      this.reply = false;
    },
    async approve() {
      return await this.$socket.emit('call', 'questions.update', {
        id: this.question._id,
        approved: true,
        rejected: false
      });
    },
    async reject() {
      return await this.$socket.emit('call', 'questions.update', {
        id: this.question._id,
        approved: false,
        rejected: true
      });
    },
    async answer() {
      return await this.$socket.emit('call', 'questions.update', {
        id: this.question._id,
        answered: true
      });
    },
    async archive() {
      return await this.$socket.emit('call', 'questions.update', {
        id: this.question._id,
        archived: true
      });
    },
    update(input) {
      console.log('updated', input);
    },
    async deleteItem() {
      return await this.$socket.emit('call', 'questions.remove', {
        id: this.question._id
      });
    },
    async present() {
      this.$socket.emit('call', 'presenter.set', {
        type: 'questions',
        stage: this.question.stage || 'main',
        id: this.question._id
      });
    }
  }
};
</script>
