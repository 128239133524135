<template>
  <div>
    <v-card class="mb-4">
      <v-card-title>
        <v-row>
          <v-col v-if="isLocked" cols="auto">
            <v-icon>mdi-lock</v-icon>
          </v-col>
          <v-col>
            <span style="word-break: keep-all" class="title">
              {{ question }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider />
      <v-list-item v-if="(isVoted || isLocked) && isShowingResults">
        <v-list-item-content>
          <div v-for="opt in options" :key="opt.id">
            <div class="mb-2 mt-5 pr-10">
              <span>{{ opt.text }}</span>
              <v-icon
                v-if="sentAnswer.includes(opt.id)"
                right
                size="16"
                class="mb-1"
              >
                mdi-account-circle
              </v-icon>
            </div>
            <v-progress-linear
              v-if="isShowingResults"
              :value="getValue(opt.id)"
              rounded
              striped
              color="primary lighten-3"
              height="25"
            >
              <template v-slot="{ value }">
                <strong>{{ value.toFixed(1) }}%</strong>
              </template>
            </v-progress-linear>
          </div>
          <v-btn
            v-if="isVoted && !isLocked"
            outlined
            class="mb-4 mt-6"
            @click="isVoted = false"
          >
            <v-icon left>mdi-refresh</v-icon>{{ $t('poll.edit_response') }}
          </v-btn>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else>
        <v-list-item-content class="py-0">
          <div>
            <v-radio-group
              v-model="answer"
              :disabled="isLocked"
              :multiple="multiple"
              :max="max"
              column
              class="flex-grow-1"
            >
              <v-alert
                v-for="opt in options"
                :key="opt.id"
                class="my-2 pr-5 d-block"
                dense
                outlined
                color="grey lighten-1"
                :text="answer.includes(opt.id)"
                label
              >
                <template #append>
                  <v-btn
                    v-if="multiple && answer.includes(opt.id)"
                    icon
                    x-small
                    @click="clearSelection(opt.id)"
                  >
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                <v-radio
                  :label="opt.text"
                  :value="opt.id"
                  :on-icon="
                    multiple
                      ? `mdi-numeric-${answer.indexOf(opt.id) + 1}-circle`
                      : '$radioOn'
                  "
                />
              </v-alert>
            </v-radio-group>
          </div>
          <div v-if="isLocked">
            <v-btn disabled depressed large class="mb-4">
              <v-icon left>mdi-lock</v-icon>{{ $t('poll.voting_closed') }}
            </v-btn>
          </div>
          <div v-else-if="isVoted && answer === sentAnswer">
            <v-btn disabled depressed large class="mb-4">
              <v-icon left>mdi-check</v-icon>{{ $t('poll.sent') }}
            </v-btn>
          </div>
          <div v-else>
            <v-btn
              color="primary"
              depressed
              large
              class="mb-4"
              :text="
                multiple
                  ? answer.length !== max
                  : !answer || Array.isArray(answer)
              "
              :loading="busy"
              @click="vote()"
            >
              {{ $t('poll.send') }}
              <v-icon right> mdi-send </v-icon>
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import { mapValues, groupBy } from 'lodash';
export default {
  name: 'Poll',
  components: {},
  props: {
    _id: {
      type: String,
      required: true
    },
    question: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean
    },
    max: {
      type: Number,
      default: 0
    },
    votes: {
      type: Object,
      default: () => {}
    },
    isLocked: Boolean,
    isShowingResults: Boolean
  },
  data() {
    return {
      answer: [],
      isVoted: false,
      sentAnswer: [],
      busy: false
    };
  },
  computed: {
    castVote() {
      return () => {};
      // return this.$fireFunc.httpsCallable('castVote')
    },
    results() {
      if (this.votes) {
        return mapValues(groupBy(this.votes), i => i.length);
      } else return {};
    },
    ratios() {
      if (this.votes) {
        return mapValues(this.results, i => i / Object.keys(this.votes).length);
      } else return {};
    }
  },
  created() {
    this.getMyVote();
  },
  methods: {
    clearSelection(opt) {
      this.answer = this.answer.filter(i => i !== opt);
    },
    getValue(opt) {
      return (this.ratios?.[opt] || 0) * 100;
    },
    async getMyVote() {
      const vote = this.votes?.[this.$store.state.auth?.user?._id];
      if (vote) {
        this.isVoted = true;
        this.answer = vote;
        this.sentAnswer = this.answer;
      }
    },
    async vote() {
      if (
        (this.multiple && !this.answer.length) ||
        (!this.multiple && (!this.answer || Array.isArray(this.answer)))
      ) {
        return;
      }
      this.busy = true;
      try {
        await this.$socket.emit('call', 'polls.vote', {
          id: this._id,
          option: this.answer
        });
        this.isVoted = true;
        this.sentAnswer = this.answer;
        // console.log('VOTE FOR', this.answer, data)
      } catch (e) {
        console.error(e);
      } finally {
        this.busy = false;
      }
    }
  }
};
</script>
