<template>
  <div>
    <Poll
      v-for="poll in activePolls"
      :id="poll.id"
      :key="poll._id"
      v-bind="poll"
    />
    <v-alert
      tile
      v-if="!activePolls.length"
      color="grey lighten-3"
      class="grey--text"
      icon="mdi-signal-off"
      light
    >
      {{ $t('poll.no_polls') }}
    </v-alert>
  </div>
</template>

<script>
import { filter, keyBy } from 'lodash';
import Poll from './Poll';
export default {
  name: 'Polls',
  components: { Poll },
  sockets: {
    poll_update(data) {
      if (data.siteId === this.site) {
        this.$set(this.pollsRaw, data._id, data);
      }
    }
  },
  data() {
    return {
      pollsRaw: {}
    };
  },
  computed: {
    site() {
      return this.$store.state.settings.siteId;
    },
    poll() {
      return this.activePolls[0];
    },
    activePolls() {
      return filter(this.pollsRaw, i => {
        return (
          i.isActive &&
          i.stage === this.$route.params.stage &&
          i.siteId === this.site
          // && !Object.keys(i.votes || {}).includes(
          //   this.$store.state.auth?.user?._id
          // )
        );
      });
    }
  },
  watch: {
    activePolls(nV, oV) {
      this.$emit('pollCount', nV.length);
      if (nV.length > oV.length) {
        this.$emit('newPoll');
      }
      if (nV.length === 0) {
        this.$emit('noPoll');
      }
    }
  },
  created() {
    this.$socket.emit('call', 'polls.getAll', (err, data) => {
      this.pollsRaw = keyBy(data, '_id');
    });
  },
  methods: {}
};
</script>
