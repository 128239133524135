<template>
  <div>
    <div style="padding:56.25% 0 0 0;position:relative;">
      <iframe
        :src="source"
        style="position:absolute;top:0;left:0;width:100%;height:100%;"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Day',
  data() {
    return {
      languages: [
        { text: 'English', value: 'en' }
        //{ text: 'Spanish', value: 'es' }
        //{ text: 'Chinese', value: 'zh' }
      ],
      selectedLangIndex: 0,
      videos: {
        '1': {
          en:
            'https://player.vimeo.com/video/717426811?h=959922103d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
        },
        '2': {
          en:
            'https://player.vimeo.com/video/717430292?h=c9b23fdedd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
        },
        '3': {
          en:
            'https://player.vimeo.com/video/717434121?h=55ec4525c9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
        }
      }
    };
  },
  computed: {
    selectedLang() {
      return this.languages[this.selectedLangIndex].value;
    },
    source() {
      if (this.videos[this.$route.params.day]) {
        return (
          this.videos[this.$route.params.day][this.selectedLang] ||
          Object.values(this.videos[this.$route.params.day])[0]
        );
      }
      return null;
    }
  }
};
</script>
