<template>
  <div class="d-flex justify-center items-center" style="height: 100%">
    <div class="d-flex flex-column justify-center" style="width: 80%">
      <v-card
        elevation="0"
        class="pa-10"
        style="flex: 0 0 auto; background: none;"
        v-if="presenter && presenter.type === 'questions'"
      >
        <div class="text-h2 white--text">
          {{ presenter.data.text }}
        </div>
        <div
          v-if="presenter.data.displayName"
          class="pl-5 mt-10 text-h4 white--text"
        >
          -- {{ presenter.data.displayName }}
        </div>
      </v-card>
      <v-card
        elevation="0"
        style="width: 100%; background: none;"
        class="pa-10"
        v-if="presenter && presenter.type === 'polls'"
      >
        <div class="text-h2 pb-10 white--text">
          {{ presenter.data.question }}
        </div>
        <div
          class="my-10 white--text"
          v-for="option in presenter.data.options"
          :key="option.id"
        >
          <div class="text-h4">{{ option.text }}</div>
          <v-progress-linear
            v-if="presenter.data.isShowingResults"
            :value="getValue(option.id)"
            rounded
            striped
            class="mt-3 text-h5 white--text"
            color="primary lighten-3"
            height="45"
          >
            <template v-slot="{ value }">
              <strong>{{ value.toFixed(1) }}%</strong>
            </template>
          </v-progress-linear>
        </div>
      </v-card>
    </div>
  </div>
</template>

<route>
{
  "meta": {
    "auth": false
  }
}
</route>

<script>
import { mapValues, groupBy } from 'lodash';

export default {
  name: 'Presenter',
  layout: 'presenter',
  data() {
    return {
      pResult: {}
    };
  },
  created() {
    this.updateView();
    setInterval(this.updateView, 3000);
  },
  mounted() {
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
  },
  beforeDestroy() {
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
  },
  computed: {
    siteId() {
      return this.$store.state.settings.siteId;
    },
    presenter() {
      return this.pResult[this.$route.query?.stage || 'main'];
    },
    results() {
      if (this.presenter?.data?.votes) {
        return mapValues(groupBy(this.presenter.data.votes), i => i.length);
      } else return {};
    },
    ratios() {
      if (this.presenter?.data?.votes) {
        return mapValues(
          this.results,
          i => i / Object.keys(this.presenter.data.votes).length
        );
      } else return {};
    }
  },
  methods: {
    getValue(opt) {
      return (this.ratios?.[opt] || 0) * 100;
    },
    async updateView() {
      const { data } = await this.$axios.get(
        '/presenter?siteId=' + this.siteId
      );
      this.pResult = data;
    }
  }
};
</script>

<style></style>
