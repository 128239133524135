<template>
  <v-container class="align-start" :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row v-if="stages.length > 1">
      <v-col>
        <v-select
          v-model="activeStage"
          :items="stages"
          label="Active Stage"
          solo
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="fill-height" align="start">
      <v-col
        v-if="settings.moderation"
        class="d-flex flex-column"
        style="width: 50%;"
      >
        <v-toolbar dark flat color="black" dense class="flex-grow-0">
          <v-tabs v-model="pendingTab">
            <v-tab>Pending</v-tab>
            <v-tab>Rejected</v-tab>
          </v-tabs>
          <v-spacer />
          <v-btn v-if="false" icon>
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card class="flex-grow-1">
          <v-tabs-items
            v-model="pendingTab"
            style="max-height: 80vh; overflow-y: scroll"
          >
            <v-tab-item>
              <Question
                v-for="item in pendingQuestions"
                :key="item._id"
                :question="item"
                :pending="true"
                :isPresenting="isPresenting(item)"
              />
              <div v-if="!pendingQuestions.length">
                <v-alert type="info" text>
                  There are no pending questions.
                </v-alert>
                <v-skeleton-loader
                  v-for="n in 5"
                  :key="n + 'bppq'"
                  boilerplate
                  type="list-item-avatar-three-line"
                />
              </div>
            </v-tab-item>
            <v-tab-item>
              <Question
                v-for="item in rejectedQuestions"
                :key="item._id"
                :question="item"
                :pending="true"
                :isPresenting="isPresenting(item)"
              />
              <div v-if="!rejectedQuestions.length">
                <v-alert type="info" text>
                  There are no rejected questions.
                </v-alert>
                <v-skeleton-loader
                  v-for="n in 5"
                  :key="n + 'bprq'"
                  boilerplate
                  type="list-item-avatar-three-line"
                />
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <v-col class="d-flex flex-column" style="width: 50%;">
        <v-toolbar dark flat color="black" dense class="flex-grow-0">
          <v-tabs v-model="liveTab">
            <v-tab>Live</v-tab>
            <v-tab>Answered</v-tab>
            <v-tab>Archived</v-tab>
          </v-tabs>
          <v-spacer />
          <v-btn v-if="false" icon>
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card class="flex-grow-1">
          <v-tabs-items
            v-model="liveTab"
            style="max-height: 80vh; overflow-y: scroll"
          >
            <v-tab-item>
              <Question
                v-for="item in liveQuestions"
                :key="item._id"
                :question="item"
                :isPresenting="isPresenting(item)"
              />
              <div v-if="!liveQuestions.length">
                <v-alert type="info" text>
                  There are no live questions.
                </v-alert>
                <v-skeleton-loader
                  v-for="n in 5"
                  :key="n + 'bplq'"
                  boilerplate
                  type="list-item-avatar-three-line"
                />
              </div>
            </v-tab-item>
            <v-tab-item>
              <Question
                v-for="item in answeredQuestions"
                :key="item._id"
                :question="item"
                :isPresenting="isPresenting(item)"
              />
              <div v-if="!answeredQuestions.length">
                <v-alert type="info" text>
                  There are no answered questions.
                </v-alert>
                <v-skeleton-loader
                  v-for="n in 5"
                  :key="n + 'bpaq'"
                  boilerplate
                  type="list-item-avatar-three-line"
                />
              </div>
            </v-tab-item>
            <v-tab-item>
              <Question
                v-for="item in archivedQuestions"
                :key="item._id"
                :question="item"
                :isPresenting="isPresenting(item)"
              />
              <div v-if="!archivedQuestions.length">
                <v-alert type="info" text>
                  There are no archived questions.
                </v-alert>
                <v-skeleton-loader
                  v-for="n in 5"
                  :key="n + 'bpahq'"
                  boilerplate
                  type="list-item-avatar-three-line"
                />
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<route>
{
  "meta": {
    "admin": true
  }
}
</route>

<script>
import { filter, orderBy, keyBy } from 'lodash';
import Question from '@/components/admin/Question';
export default {
  name: 'Moderation',
  components: { Question },
  async created() {
    this.$socket.emit(
      'call',
      'questions.getAll',
      { subscribe: true },
      (err, data) => {
        this.questions = keyBy(data, '_id');
        this.loading = false;
      }
    );
    this.$socket.emit(
      'call',
      'presenter.get',
      { subscribe: true },
      (err, data) => {
        if (data) {
          Object.keys(data).forEach(k => {
            this.$set(this.presenter, k, data[k]);
          });
        }
      }
    );
  },
  data() {
    return {
      stages: [{ value: 'main', text: 'Main Stage' }],
      activeStage: 'main',
      loading: true,
      liveTab: 0,
      pendingTab: 0,
      subscription: null,
      questions: {},
      presenter: {}
    };
  },
  sockets: {
    question_update(data) {
      if (data.siteId === this.site) {
        this.$set(this.questions, data._id, data);
      }
    },
    question_delete(id) {
      this.$delete(this.questions, id);
    },
    presenter_update(data) {
      if (data) {
        Object.keys(data).forEach(k => {
          this.$set(this.presenter, k, data[k]);
        });
      }
    }
  },
  computed: {
    site() {
      return this.$store.state.settings.siteId;
    },
    settings() {
      return {
        moderation: true //this.$store.state.settings?.questions?.moderation
      };
    },
    pendingQuestions() {
      const filtered = filter(this.questions, i => {
        return !i.approved && !i.rejected && i.stage === this.activeStage;
      });
      const ordered = orderBy(filtered, ['createdAt'], ['desc']);
      return ordered;
    },
    liveQuestions() {
      const filtered = filter(this.questions, i => {
        return (
          i.approved &&
          !i.archived &&
          !i.answered &&
          i.stage === this.activeStage
        );
        // return ((i.approved || (i.approved === null && !this.settings.moderation)) && !i.archived && !i.answered)
      });
      const ordered = orderBy(filtered, ['createdAt'], ['desc']);
      return ordered;
    },
    answeredQuestions() {
      const filtered = filter(this.questions, i => {
        return (
          i.approved &&
          i.answered &&
          !i.archived &&
          i.stage === this.activeStage
        );
        // return ((i.approved || (i.approved === null && !this.settings.moderation)) && i.answered && !i.archived)
      });
      const ordered = orderBy(filtered, ['createdAt'], ['desc']);
      return ordered;
    },
    archivedQuestions() {
      const filtered = filter(this.questions, i => {
        return i.approved && i.archived && i.stage === this.activeStage;
        // return ((i.approved || (i.approved === null && !this.settings.moderation)) && i.archived)
      });
      const ordered = orderBy(filtered, ['createdAt'], ['desc']);
      return ordered;
    },
    rejectedQuestions() {
      const filtered = filter(this.questions, i => {
        return !!i.rejected && i.stage === this.activeStage;
      });
      const ordered = orderBy(filtered, ['createdAt'], ['desc']);
      return ordered;
    }
  },
  async mounted() {},
  beforeDestroy() {},
  methods: {
    isPresenting(q) {
      return this.presenter[q.stage]?.id === q._id;
    }
  },
  head() {
    return {
      title: 'Q&A Moderation'
    };
  }
};
</script>
