<template>
  <v-card light>
    <v-toolbar>
      <v-toolbar-title>{{ edit ? 'Edit' : 'New' }} Poll</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <ValidationObserver ref="observer" v-slot="{}">
      <v-form class="pt-3" @submit.prevent="save">
        <v-card-text class="">
          <ValidationProvider
            v-slot="{ errors }"
            name="question"
            rules="required"
          >
            <v-text-field
              v-model="question"
              outlined
              label="Question"
              placeholder="What would you like to ask?"
              name="question"
              :error-messages="errors"
              required
            />
          </ValidationProvider>
          <div class="">
            <ValidationProvider
              v-for="opt in options"
              v-slot="{ errors }"
              :key="opt.id"
              name="option"
              rules="required"
            >
              <v-text-field
                v-model="opt.text"
                name="option"
                :error-messages="errors"
                prepend-icon="mdi-circle-double"
                append-icon="mdi-close-circle-outline"
                required
                @click:append="deleteOption(opt.id)"
              />
            </ValidationProvider>
            <div class="d-flex justify-center align-center">
              <v-btn depressed @click="createOption()">
                <v-icon left>mdi-plus</v-icon>
                Add Option
              </v-btn>
            </div>

            <div v-if="allowMultiple">
              <v-checkbox
                v-model="multiple"
                dense
                label="Allow to select multiple options"
              />
              <v-select
                v-if="multiple"
                v-model="max"
                style="max-width: 150px"
                dense
                outlined
                :items="maxItems"
              />
            </div>
          </div>
          <v-alert v-if="message" :type="messageType" text>
            {{ message }}
          </v-alert>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn type="submit" color="primary" depressed :loading="busy">
            <v-icon left> mdi-check </v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </ValidationObserver>
  </v-card>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { id } from '@/lib/utils';
import { cloneDeep } from 'lodash';

export default {
  name: 'AdminPollCreate',
  components: { ValidationObserver, ValidationProvider },
  props: {
    edit: {
      type: Object,
      default: null
    },
    stage: {
      type: String,
      default: 'main'
    }
  },
  data() {
    return {
      question: '',
      options: [],
      allowMultiple: false,
      multiple: false,
      max: null,
      message: null,
      messageType: null,
      busy: false
    };
  },
  computed: {
    maxItems() {
      const list = Array.from({ length: this.options.length }, (_, i) => i + 1);
      const objList = list.map(i => {
        return { text: i, value: i };
      });

      objList.unshift({ text: 'Unlimited', value: null });
      return objList;
    }
  },
  created() {
    if (this.edit) {
      this.question = this.edit.question;
      this.options = cloneDeep(this.edit.options);
      this.multiple = !!this.edit.multiple;
      this.max = this.edit.max || null;
    }
  },
  methods: {
    createOption(text) {
      this.options.push({
        id: id(),
        text: text || ''
      });
    },
    deleteOption(id) {
      this.options = this.options.filter(i => i.id !== id);
    },
    reset() {
      this.question = '';
      this.multiple = false;
      this.max = null;
      this.options = [];
      this.createOption();
      this.message = null;
      this.messageType = null;
      this.$refs.observer.reset();
    },
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return;
      }
      this.busy = true;
      try {
        if (this.edit) {
          await this.$socket.emit('call', 'polls.update', {
            id: this.edit._id,
            question: this.question,
            options: this.options,
            multiple: this.multiple,
            max: this.max
          });
        } else {
          const { _id: newId } = await this.$socket.emit(
            'call',
            'polls.create',
            {
              question: this.question,
              options: this.options,
              multiple: this.multiple,
              max: this.max,
              created: new Date(),
              isActive: false,
              isLocked: false,
              isShowingResults: false,
              stage: this.stage
            }
          );
          this.$router.push('/admin/polls/' + newId);
        }
        this.$emit('close');
        this.reset();
      } catch (e) {
        this.message = e.message;
        this.messageType = 'error';
      } finally {
        this.busy = false;
      }
    }
  }
};
</script>
