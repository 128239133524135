var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"15"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-form',{staticClass:"pt-3",on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('v-card-text',{staticClass:"px-6 pb-0"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('login.email_label'),"b-placeholder":_vm.$t('login.email_placeholder'),"name":"email","prepend-icon":"mdi-email","type":"email","disabled":_vm.busy || _vm.disabled,"error-messages":errors,"required":"","b-class":{ 'text-caption': !_vm.email }},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),(_vm.requestName)?_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('login.name_label'),"b-placeholder":_vm.$t('login.name_placeholder'),"name":"name","prepend-icon":"mdi-account","type":"text","disabled":_vm.busy || _vm.disabled,"error-messages":errors,"required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}):_vm._e(),(_vm.usePassword)?_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('login.password_label'),"name":"password","prepend-icon":"mdi-lock","type":"password","disabled":_vm.busy || _vm.disabled,"error-messages":errors,"required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.login($event)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}):_vm._e(),(_vm.useKvkk)?_c('ValidationProvider',{attrs:{"name":"kvkk","rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"disabled":_vm.busy || _vm.disabled,"error-messages":errors},model:{value:(_vm.kvkk),callback:function ($$v) {_vm.kvkk=$$v},expression:"kvkk"}},[_c('template',{slot:"label"},[_c('span',{staticClass:"text-caption",staticStyle:{"line-height":"1.2"}},[_vm._v(" By checking this box I declare that I have read and agree to the terms of the "),_c('TermsDialog',{attrs:{"lang":"en"}},[_vm._v(" Privacy Notice as displayed here. ")])],1)])],2)]}}],null,true)}):_vm._e(),(_vm.useCaptcha)?_c('ValidationProvider',{attrs:{"name":"captcha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.captcha),expression:"captcha"}],attrs:{"name":"captcha","type":"hidden","disabled":""},domProps:{"value":(_vm.captcha)},on:{"input":function($event){if($event.target.composing){ return; }_vm.captcha=$event.target.value}}}),_c('div',{staticClass:"flex justify-center mt-4"},[_c('vue-recaptcha',{ref:"recaptcha",attrs:{"sitekey":"6Ld56ZAfAAAAAGPaluEAi8FuyUzxnVuj_w9WtHEB","theme":"light"},on:{"verify":_vm.handleCaptcha,"expired":_vm.handleCaptchaError,"error":_vm.handleCaptchaError}})],1),_c('div',{staticClass:"text-red-500 text-xs text-center"},_vm._l((errors),function(i){return _c('p',{key:i},[_vm._v(_vm._s(i))])}),0)]}}],null,true)}):_vm._e(),(_vm.message)?_c('v-alert',{attrs:{"dense":"","type":_vm.messageType,"text":""}},[_vm._v(" "+_vm._s(_vm.$t('errors.' + _vm.msgCode))+" ")]):_vm._e()],1),_c('v-card-actions',{staticClass:"px-6 pb-6 pt-2"},[_c('v-btn',{attrs:{"loading":_vm.busy,"disabled":_vm.busy || _vm.disabled,"depressed":"","block":"","color":"primary","large":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('login.login'))+" ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }