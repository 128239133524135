<template>
  <v-app class="background">
    <v-navigation-drawer
      v-if="!!menu.length"
      app
      v-model="drawer"
      temporary
      class="pt-16"
    >
      <v-list nav dense>
        <template v-for="item in menu">
          <v-list-item
            v-if="!item.children || $vuetify.breakpoint.mdAndUp"
            :to="item.to"
            :key="item.to"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-list-group v-else :key="item.to" :value="true" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.children"
              :exact="child.exact"
              :key="child.to"
              :to="child.to"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-app-bar style="z-index: 40;" dark color="primary">
        <v-app-bar-nav-icon
          dark
          v-if="$vuetify.breakpoint.smAndDown && !!menu.length"
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <div class="d-flex align-center">
          <v-img
            v-if="$vuetify.breakpoint.lgAndUp"
            contain
            height="55"
            width="115"
            :src="require('@/assets/logo-white.png')"
          />
          <v-img
            v-if="$vuetify.breakpoint.mdAndDown"
            contain
            height="45"
            width="100"
            :src="require('@/assets/logo-white.png')"
          />
        </div>
        <v-spacer />
        <v-tabs centered v-if="$vuetify.breakpoint.mdAndUp">
          <v-tab v-for="item in menu" :to="item.to" :key="item.to">
            {{ item.title }}
          </v-tab>
        </v-tabs>
        <ProfileAppBar />
      </v-app-bar>
      <router-view />
    </v-main>
    <v-dialog :value="streamPopup" width="500">
      <v-card>
        <v-card-text class="pt-5 text-h5">
          Yayınımızın soru cevap kısmı başlamak üzeredir.
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            to="/stage/main"
            class="ma-2"
            @click="streamPopup = false"
          >
            Ana Sahne
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CookieBar />
  </v-app>
</template>

<script>
import CookieBar from '@/components/CookieBar';
import ProfileAppBar from '@/components/ProfileAppBar';

export default {
  name: 'DefaultLayout',
  components: { ProfileAppBar, CookieBar },
  data() {
    return {
      drawer: false,
      streamPopup: false,
      menu: [
        // { to: '/exhibition', title: 'Exhibition' },
        // { to: '/stage/main', title: 'Main Stage' }
      ]
    };
  },
  sockets: {
    stream_popup() {
      if (this.$route.name !== 'stage-stage') {
        this.streamPopup = true;
      }
    }
  },
  created() {},
  beforeDestroy() {},
  watch: {},
  methods: {}
};
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

.background {
  background-color: #fff !important;
  background-image: url('../assets/Main_Page_Bg.png') !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
</style>
