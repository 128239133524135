<template>
  <v-card class="my-3 no-page-break" outlined>
    <v-card-title class="d-flex" style="word-break: keep-all;">
      <div>{{ poll.question }}</div>
      <v-spacer />
      <div>
        <v-icon class="left" small>mdi-account-multiple</v-icon>
        {{ count }}
      </div>
    </v-card-title>
    <v-card-text>
      <div v-for="opt in poll.options" :key="opt.id" class="mt-5">
        <div class="d-flex">
          <p class="mb-0">{{ opt.text }}</p>
          <v-spacer />
          <p class="mb-0">
            <v-icon class="left" small>mdi-account-outline</v-icon>
            {{ results[opt.id] || 0 }}
          </p>
        </div>
        <v-progress-linear
          :value="getValue(opt.id)"
          rounded
          striped
          color="blue lighten-3"
          height="25"
        >
          <template v-slot="{ value }">
            <strong>{{ value.toFixed(1) }}%</strong>
          </template>
        </v-progress-linear>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { groupBy, mapValues } from 'lodash';

export default {
  name: 'AdminPollItemReport',
  props: {
    poll: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  computed: {
    count() {
      return Object.keys(this.poll.votes || {}).length;
    },
    results() {
      if (this.poll.votes) {
        return mapValues(groupBy(this.poll.votes), i => i.length);
      } else return {};
    },
    ratios() {
      if (this.poll.votes) {
        return mapValues(
          this.results,
          i => i / Object.keys(this.poll.votes).length
        );
      } else return {};
    }
  },
  methods: {
    getValue(opt) {
      return (this.ratios?.[opt] || 0) * 100;
    }
  }
};
</script>

<style>
.no-page-break {
  display: inline-block;
  width: 100%;
  page-break-inside: avoid;
}
</style>
