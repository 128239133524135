<template>
  <v-card light class="mb-4">
    <v-toolbar v-if="title" flat dense color="grey lighten-2">
      <v-icon class="mr-2">
        mdi-forum
      </v-icon>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>
    <ValidationObserver ref="observer" v-slot="{}">
      <v-list-item>
        <v-list-item-avatar v-if="false">
          <v-icon>mdi-pencil</v-icon>
        </v-list-item-avatar>
        <v-list-item-content class="py-0">
          <ValidationProvider
            v-slot="{ errors, dirty }"
            name="question"
            rules="required"
            mode="passive"
          >
            <v-textarea
              v-model="question"
              :disabled="busy"
              :loading="busy"
              solo
              flat
              counter="250"
              :rows="rows || 1"
              :label="$t('questions.enter_question')"
              name="question"
              :error-messages="dirty ? errors : []"
              required
              @keypress.enter.prevent="ask"
            />
          </ValidationProvider>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item dense>
        <v-list-item-content class="py-0">
          <v-checkbox
            v-if="anonymousPermission"
            v-model="anonymous"
            color="primary"
            :label="$t('questions.ask_anonymously')"
          />
        </v-list-item-content>
        <v-list-item-action>
          <v-btn color="primary" text @click="ask">
            {{ $t('questions.send') }}
            <v-icon right> mdi-send </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </ValidationObserver>
    <v-snackbar v-model="snackbar" absolute light>
      <v-icon left>mdi-check</v-icon>
      {{ $t('questions.sent_message') }}
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
// import { db, FieldValue } from '../../../lib/db'

export default {
  name: 'AskQuestion',
  props: ['title', 'rows'],
  components: { ValidationObserver, ValidationProvider },
  data() {
    return {
      question: '',
      busy: false,
      anonymous: false,
      snackbar: false
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      anonymousPermission: state => state.settings?.questions?.anonymous
    })
  },
  methods: {
    async ask() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return;
      }
      /*await db.collection('questions').add({
        name:
          this.anonymous && this.anonymousPermission ? null : this.user.name,
        owner: this.user._id,
        text: this.question,
        likes: [],
        approved: false,
        archived: false,
        answered: false,
        rejected: false,
        created: FieldValue.serverTimestamp(),
      })
      this.question = ''
      this.$refs.observer.reset()
      //this.snackbar = true
      */

      this.$socket.emit(
        'call',
        'questions.ask',
        {
          stage: this.$route.params.stage,
          question: this.question,
          isAnonymous: this.anonymous,
          displayName:
            this.anonymous && this.anonymousPermission
              ? 'Anonymous'
              : this.user.name || this.user.email
        },
        err => {
          // console.log(data);
          if (err) {
            console.error(err);
          }
          this.question = '';
          this.$refs.observer.reset();
          this.snackbar = true;
        }
      );
    }
  }
};
</script>
<style></style>
