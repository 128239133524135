import Vue from 'vue';
import VueSocketIO from 'vue-socket.io';
import io from 'socket.io-client';
import store from '../store';

import settings from '../settings';

const socket = io(settings.baseUrl, {
  transports: ['websocket'],
  secure: true,
  autoConnect: false
});

/*
socket.on('reconnect_attempt', () => {
  socket.io.opts.transports = ['polling', 'websocket'];
});
*/
Vue.use(
  new VueSocketIO({
    debug: false,
    connection: socket,
    vuex: {
      store,
      actionPrefix: 'socket_',
      mutationPrefix: 'SOCKET_'
    }
  })
);

export default socket;
