<template>
  <v-container class="align-start" :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row>
      <v-col>
        <v-card class="mx-auto" max-width="600">
          <v-toolbar flat dense>
            <v-toolbar-title>
              <span class="subheading">MULTIPLIER</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card-text>
            <v-row class="mb-4" justify="space-between">
              <v-col class="text-left">
                <span class="text-h2 font-weight-light" v-text="mx"></span>
                <span class="subheading font-weight-light mr-1">X</span>
              </v-col>
              <v-col class="text-right">
                <v-btn v-if="mx !== currentMx" dark depressed fab @click="save">
                  <v-icon large>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-slider
              v-model="mx"
              track-color="grey"
              always-dirty
              min="1"
              max="200"
            >
              <template v-slot:prepend>
                <v-icon @click="decrement">
                  mdi-minus
                </v-icon>
              </template>

              <template v-slot:append>
                <v-icon @click="increment">
                  mdi-plus
                </v-icon>
              </template>
            </v-slider>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="mx-auto" max-width="600">
          <v-toolbar flat dense>
            <v-toolbar-title>
              <span class="subheading">ENABLE COUNTER</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-spacer />
              <v-switch
                v-model="toggleState"
                inset
                :label="toggleState ? 'ENABLED' : 'DISABLED'"
                @change="stateChange"
              ></v-switch>
              <v-spacer />
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<route>
{
  "meta": {
    "admin": true
  }
}
</route>

<script>
export default {
  name: 'Counter',
  components: {},
  async created() {},
  data() {
    return {
      mx: 1,
      currentMx: 1,
      toggleState: false
    };
  },
  sockets: {},
  computed: {},
  async mounted() {
    this.$socket.emit(
      'call',
      'sites.get',
      { id: this.$store.state.settings.siteId },
      (err, data) => {
        this.currentMx = data?.count?.multiplier;
        this.mx = data?.count?.multiplier;
        this.toggleState = data?.count?.enable;
      }
    );
  },
  beforeDestroy() {},
  methods: {
    decrement() {
      this.mx--;
    },
    increment() {
      this.mx++;
    },
    save() {
      this.$socket.emit(
        'call',
        'sites.update',
        { id: this.$store.state.settings.siteId, 'count.multiplier': this.mx },
        err => {
          if (err) {
            alert(err);
          }
          this.currentMx = this.mx;
        }
      );
    },
    stateChange() {
      this.$socket.emit(
        'call',
        'sites.update',
        {
          id: this.$store.state.settings.siteId,
          'count.enable': this.toggleState
        },
        err => {
          if (err) {
            alert(err);
          }
        }
      );
    }
  },
  head() {}
};
</script>
