<template>
  <div />
</template>

<route>
{
  "redirect": "/stage/main",
  "meta": {
    "auth": false
  }
}
</route>

<script>
export default {
  name: 'Home',
  components: {}
};
</script>

<style></style>
