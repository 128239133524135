import Vue from 'vue';
import Vuex from 'vuex';
import { merge } from 'lodash';
import axios from '../plugins/axios';
import settings from '../settings';
// modules
import auth from './auth';
// import questions from './questions'
// import polls from './polls'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    settings
  },
  mutations: {
    MERGE_SETTINGS(state, payload) {
      state.settings = merge(state.settings, payload);
    }
  },
  actions: {
    async updateSettings({ state, commit }) {
      try {
        const { data } = await axios.get('/settings/' + state.settings.siteId);
        commit('MERGE_SETTINGS', data);
      } catch (e) {
        console.error('UPDATE FAILED');
        console.error(e);
        throw e;
      }
    }
  },
  modules: {
    auth
  }
});
