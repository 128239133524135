<template>
  <div style="overflow: hidden">
    <v-toolbar dense light flat color="transparent">
      <v-tabs v-model="order">
        <v-tab>{{ $t('questions.latest') }}</v-tab>
        <v-tab>{{ $t('questions.popular') }}</v-tab>
      </v-tabs>
      <span style="width: 100px" class="caption text-right">
        {{ filteredQuestions.length }} {{ $t('questions.questions') }}
      </span>
    </v-toolbar>
    <v-card style="max-height: calc(90vh - 320px); overflow-y: scroll">
      <template v-if="!orderedQuestions || !orderedQuestions.length">
        <v-alert type="info" text>
          {{ $t('questions.no_questions') }}
        </v-alert>
        <v-skeleton-loader
          v-for="n in 2"
          :key="n + 'bppq'"
          boilerplate
          type="list-item-avatar-three-line"
        />
      </template>
      <Question
        v-for="item in orderedQuestions"
        :key="item._id"
        :question="item"
      />
    </v-card>
  </div>
</template>

<script>
import { orderBy, keyBy, filter, take } from 'lodash';
import Question from './Question';

export default {
  name: 'ListQuestions',
  components: { Question },
  data() {
    return {
      loading: true,
      order: 0,
      questions: {}
    };
  },
  sockets: {
    question_update(data) {
      if (data.stage === this.stage && data.siteId === this.site) {
        this.$set(this.questions, data._id, data);
      }
    },
    question_delete(id) {
      this.$delete(this.questions, id);
    }
  },
  computed: {
    stage() {
      return this.$route.params.stage;
    },
    site() {
      return this.$store.state.settings.siteId;
    },
    moderation() {
      return this.$store.state.settings.questions.moderation;
    },
    filteredQuestions() {
      return filter(this.questions, i => {
        if (this.moderation) {
          return (
            i.stage === this.stage &&
            i.siteId === this.site &&
            (i.approved || i.userId === this.$store.state.auth.user._id) &&
            !i.archived
          );
        }
        return i.stage === this.stage && i.siteId === this.site && !i.archived;
      });
    },
    orderedQuestions() {
      if (this.order === 0) {
        return take(orderBy(this.filteredQuestions, 'createdAt', 'desc'), 100);
      } else if (this.order === 1) {
        return take(
          orderBy(
            this.filteredQuestions,
            [i => i.likes?.length, 'createdAt'],
            ['desc', 'desc']
          ),
          100
        );
      } else {
        return take(this.filteredQuestions, 100);
      }
    }
  },
  created() {
    // this.$store.dispatch('questions/bindQuestionsRef')
    this.$socket.emit(
      'call',
      'questions.getAll',
      { subscribe: true },
      (err, data) => {
        this.questions = keyBy(data, '_id');
        this.loading = false;
      }
    );
  },
  beforeDestroy() {},
  methods: {}
};
</script>
