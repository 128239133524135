var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',[_c('v-list-item',{attrs:{"ripple":false,"three-line":"","to":("/admin/polls/" + (_vm.poll._id)),"color":"blue"}},[_c('v-list-item-avatar',{attrs:{"color":_vm.avatarColor}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.avatarIcon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.poll.question)+" ")]),_c('v-list-item-subtitle',[_c('span',{staticClass:"mr-4"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"16"}},[_vm._v(" mdi-account ")]),_vm._v(" "+_vm._s((_vm.poll.votes && Object.keys(_vm.poll.votes).length) || 0)+" ")],1),(_vm.poll.isLocked)?_c('span',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-lock ")])],1):_vm._e(),(_vm.poll.isShowingResults)?_c('span',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"size":"16","color":"blue"}},[_vm._v(" mdi-signal ")])],1):_vm._e(),(_vm.isPresenting)?_c('span',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"size":"16","color":"amber"}},[_vm._v(" mdi-presentation-play ")])],1):_vm._e()])],1),_c('v-list-item-action',[(_vm.poll.isActive || hover)?_c('div',[(!_vm.isPresenting)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":""},on:{"click":function($event){return _vm.setPresenting(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-presentation")])],1)]}}],null,true)},[_c('span',[_vm._v("Show in Presenter View")])]):_vm._e(),(!_vm.poll.isLocked)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":""},on:{"click":function($event){return _vm.setLocked(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-lock-open-variant-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Lock voting")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":""},on:{"click":function($event){return _vm.setLocked(false)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-lock")])],1)]}}],null,true)},[_c('span',[_vm._v("Unlock voting")])]),(!_vm.poll.isShowingResults)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":""},on:{"click":function($event){return _vm.setShowingResults(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-signal-off")])],1)]}}],null,true)},[_c('span',[_vm._v("Show results")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.setShowingResults(false)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-signal")])],1)]}}],null,true)},[_c('span',[_vm._v("Hide results")])]),(!_vm.poll.isActive)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","large":"","color":"green"},on:{"click":function($event){return _vm.setActive(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-play-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Activate")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","large":"","color":"red"},on:{"click":function($event){return _vm.setActive(false)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-stop-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Deactivate")])])],1):_vm._e()])],1),_c('v-divider')],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }