<template>
  <v-container>
    <v-row class="d-print-none">
      <v-col>
        <v-select
          v-model="activeStage"
          :items="stages"
          label="Active Stage"
          solo
        ></v-select>
      </v-col>
    </v-row>
    <v-row align="start">
      <v-col cols="12" style="position: relative;">
        <AdminPollItemReport
          v-for="poll in polls"
          :key="poll._id"
          :poll="poll"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<route>
{
  "meta": {
    "admin": true
  }
}
</route>

<script>
import { orderBy, filter, keyBy } from 'lodash';
import AdminPollItemReport from '@/components/admin/PollItemReport';

export default {
  name: 'PollsAdmin',
  layout: 'default',
  components: { AdminPollItemReport },
  async created() {
    await this.$socket.emit('call', 'polls.getAll', (err, data) => {
      this.pollsRaw = keyBy(data, '_id');
    });
    this.$socket.emit(
      'call',
      'presenter.get',
      { subscribe: true },
      (err, data) => {
        if (data) {
          Object.keys(data).forEach(k => {
            this.$set(this.presenter, k, data[k]);
          });
        }
      }
    );
  },
  data() {
    return {
      stages: [{ value: 'main', text: 'Ana Salon' }],
      activeStage: 'main',
      pollsRaw: {},
      newPollDialog: false,
      presenter: {}
    };
  },
  sockets: {
    poll_update(data) {
      this.$set(this.pollsRaw, data._id, data);
    },
    presenter_update(data) {
      if (data) {
        Object.keys(data).forEach(k => {
          this.$set(this.presenter, k, data[k]);
        });
      }
    }
  },
  computed: {
    polls() {
      const filtered = filter(this.pollsRaw, { stage: this.activeStage });
      const ordered = orderBy(filtered, ['createdAt'], ['desc']);
      return ordered;
    }
  },
  async mounted() {},
  beforeDestroy() {},
  methods: {
    isPresenting(poll) {
      return this.presenter[poll.stage]?.id === poll._id;
    }
  },
  head() {
    return {
      title: 'Polls Management'
    };
  }
};
</script>
