<template>
  <div>
    <v-list-item dense :two-line="$vuetify.breakpoint.mdAndUp">
      <v-list-item-avatar
        v-if="false && $vuetify.breakpoint.mdAndUp"
        color="grey lighten-2"
      >
        {{ initials }}
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ question.displayName || 'Anonymous' }}
          <span
            v-if="$vuetify.breakpoint.smAndDown && question.createdAt"
            class="text-secondary font-weight-light"
          >
            {{ $moment(question.createdAt).format('HH:mm') }}
          </span>
        </v-list-item-title>
        <v-list-item-subtitle
          v-if="$vuetify.breakpoint.mdAndUp && question.createdAt"
        >
          {{ $moment(question.createdAt).format('HH:mm') }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-chip
          small
          :outlined="!isLiked"
          color="primary"
          :disabled="busy"
          @click="like"
        >
          <v-icon size="small" left> mdi-thumb-up-outline </v-icon>
          {{ likeCount }}
        </v-chip>
        <v-chip
          v-if="question.answered"
          outlined
          small
          color="green"
          class="mt-2"
        >
          <v-icon size="small" left> mdi-check </v-icon>
          Answered
        </v-chip>
      </v-list-item-action>
    </v-list-item>
    <p class="body-2 text--secondary mt-0 px-md-4 px-4">
      {{ question.text }}
    </p>
    <div class="px-3" v-if="question.reply">
      <v-alert color="grey lighten-3" dense class="text-caption" border="left">
        <p class="pa-0 ma-0 font-weight-bold">Moderator:</p>
        {{ question.reply }}
      </v-alert>
    </div>
    <v-divider />
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import { initials } from '@/lib/helpers'

export default {
  name: 'Question',
  props: {
    question: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      busy: false
    };
  },
  computed: {
    initials() {
      return ''; // initials(null, this.question.name)
    },
    isLiked() {
      return this.question.likes.includes(this.uid);
    },
    likeCount() {
      return this.question.likes ? this.question.likes.length : 0;
    },
    ...mapState({
      uid: store => store.auth.user._id
    }),
    ownQst() {
      return this.uid === this.question.userId;
    }
  },
  methods: {
    async like() {
      this.busy = true;
      try {
        if (this.isLiked) {
          this.$socket.emit('call', 'questions.unlike', {
            question: this.question._id
          });
          //this.$fireAnalytics.logEvent('question_unlike')
          //const unlikeQ = this.$fireFunc.httpsCallable('unlikeQuestion')
          //await unlikeQ(this.question.id)
        } else {
          this.$socket.emit('call', 'questions.like', {
            question: this.question._id
          });
          //this.$fireAnalytics.logEvent('question_like')
          //const likeQ = this.$fireFunc.httpsCallable('likeQuestion')
          //await likeQ(this.question.id)
        }
      } finally {
        this.busy = false;
      }
    }
  }
};
</script>
