<template>
  <div></div>
</template>

<route>
{
  "meta": {
    "admin": true
  }
}
</route>

<script>
export default {
  name: 'Blank'
};
</script>

<style></style>
