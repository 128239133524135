<template>
  <v-app class="background">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'PresenterLayout',
  components: {}
};
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

//@media #{map-get($display-breakpoints, 'md-and-down')} {
.background {
  background-color: #fff !important;
  background-image: url('../assets/Sunum_Bg-02.jpg') !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
//}
/*
@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .background {
    background-color: #fff !important;
    background-image: url('../assets/2021.png'), url('../assets/Bg.png') !important;
    background-position: bottom right, center center !important;
    background-repeat: no-repeat, no-repeat !important;
    background-size: auto 70%, cover !important;
  }
}
*/
</style>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';
</style>
