<template>
  <div>
    <v-tabs v-model="tab" centered grow dark background-color="primary">
      <v-tab
        v-for="tab in filteredTabs"
        :key="'_tabtitle_' + tab.title"
        :disabled="tab.disabled"
      >
        <v-badge bordered inline :value="tab.badge()" :content="tab.badge()">
          <v-icon class="mr-2">
            {{ tab.icon }}
          </v-icon>
          {{ $t('tabs.' + tab.title) }}
        </v-badge>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" style="background: none">
      <v-tab-item
        v-for="tab in filteredTabs"
        :key="'_tabcomp_' + tab.title"
        eager
      >
        <component
          :is="tab.component"
          @newPoll="newPoll"
          @noPoll="noPoll"
          @pollCount="pollCount = $event"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import WebRTC from '@/components/WebRTC_Janus'
import Qna from './qna/Qna';
import Polls from '../polls/Polls';

export default {
  name: 'Sidebar',
  components: {},
  data() {
    return {
      pollCount: 0,
      tabs: [
        {
          title: 'camera',
          icon: 'mdi-camera',
          component: null,
          badge: () => false,
          filter: (s, c) => {
            return s?.camera?.enabled && !!c?.camera;
          }
        },
        {
          title: 'questions',
          icon: 'mdi-forum',
          component: Qna,
          badge: () => false,
          filter: s => {
            return s?.questions?.enabled;
          }
        },
        {
          title: 'polls',
          icon: 'mdi-equalizer',
          component: Polls,
          badge: () => this.pollCount,
          filter: s => {
            return s?.voting?.enabled;
          }
        }
      ],
      tab: 0
    };
  },
  computed: {
    ...mapState({
      settings: store => store.settings,
      claims: store => store.auth.claims
    }),
    filteredTabs() {
      return this.tabs.filter(i => {
        if (i.filter === undefined) {
          return true;
        }
        if (typeof i.filter === 'function') {
          return i.filter(this.settings, this.claims);
        }
        return i.filter;
      });
    }
  },
  methods: {
    newPoll() {
      this.tab = 1;
    },
    noPoll() {
      this.tab = 0;
    }
  }
};
</script>
